import React, { useEffect } from 'react';

import './App.css';

function App() {
  useEffect(() => {
    window.location.replace('https://www.theknot.com/us/carin-larsson-and-stephen-lau-sep-2024')
  }, []);
  return (
    <div className="App" style={{
      width: "100%"
    }}>
    </div>
  );
}

export default App;

// @ts-nocheck
import React from "react";
import "./invite.css";
import useWindowDimensions from "../../useWindowDimensions";

import HTMLFlipBook from "react-pageflip";
import image1 from "./wedding bundle-1.png";
import image2 from "./wedding bundle-2.png";
import image3 from "./wedding bundle-3.png";
import image4 from "./wedding bundle-4.png";
// import image41 from "./wedding bundle-4-1.png"
// import image42 from "./wedding bundle-4-2.png"
import image5 from "./wedding bundle-5.png";
import arrowImage from "./heartarrow.svg";
import rsvpTicketImage from "./RSVP_Back-1.png";

// import image6 from "./wedding bundle-6.png"
// import image7 from "./wedding bundle-7.png"

const image_original_width = 778;
const image_original_height = 1050;

function Invite() {
  const { height, width } = useWindowDimensions();
  let targetHeight = image_original_height;
  let targetWidth = image_original_width;
  if (height < image_original_height) {
    //Scale because it is too short to show a full page
    targetHeight = height;
    targetWidth = (height / image_original_height) * image_original_width;
  }
  if (width < image_original_width) {
    //Scale because it is too short to show a full page
    const oldTargetWidth = targetWidth;
    targetWidth = width;
    targetHeight = (width / oldTargetWidth) * targetHeight;
  }

  const pageStyle = {
    width: `${targetWidth}px`,
    height: `${targetHeight}px`,
  };

  const onNarrowScreen = width < targetWidth * 2;
  console.log("width: ", width, "target width", targetWidth, onNarrowScreen);
  return (
    <div className="App">
      <header className="App-header">
        <HTMLFlipBook
          showCover={true}
          key="large"
          width={targetWidth}
          height={targetHeight}
        >
          {/* <div style={pageStyle} className="blank"></div> */}
          <div className="demoPage">
            <img style={pageStyle} alt="Passport frontpage" src={image1}></img>
          </div>
          <div className="demoPage">
            <img style={pageStyle} alt="Invite to wedding" src={image2}></img>
          </div>
          <div className="">
            <img style={pageStyle} alt="Passport Page" src={image3}></img>
          </div>
          <div className="demoPage">
            <img style={pageStyle} alt="half schedule" src={image4}></img>
          </div>
          <div style={pageStyle} className="arrow">
            <div style={{ marginTop: "25%", fontSize: "2.5rem" }}>
              Click on ticket below to RSVP!
            </div>
            <img
              style={{ marginTop: "2.5rem", maxHeight: "50%" }}
              src={arrowImage}
              alt="arrow down"
            />
          </div>
          <div className="demoPage">
            <img style={pageStyle} alt="Passport back cover" src={image5}></img>
          </div>
        </HTMLFlipBook>

        <a
          href="https://www.theknot.com/us/carin-larsson-and-stephen-lau-sep-2024/rsvp"
          style={{ marginTop: "2.5rem" }}
        >
          <img
            style={{ maxWidth: "100%" }}
            src={rsvpTicketImage}
            alt="Ticket to RSVP"
          />
        </a>
        <div style={{ marginTop: "1rem", fontSize: "2.5rem" }}>
          Click on ticket to RSVP!
        </div>
      </header>
    </div>
  );
}

export default Invite;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Invite from "./pages/invite/invite"
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RSVP from './pages/rsvp/RSVP';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <App/>
  },
  {
    path: "/invite/",
    element: <Invite />,
  },
  {
    path: "/rsvp/",
    element: <RSVP />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <ul className="tabs">
          <li className="a-tab"><a className="site-link" href="https://theknot.com/carinochstephen">Wedding Site</a></li>
          <li className="a-tab"><a className="site-link" href="https://www.theknot.com/us/carin-larsson-and-stephen-lau-sep-2024/rsvp">RSVP</a></li>
          <li className="a-tab"><a className="site-link" href="/invite">Invite</a></li>
        </ul> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

